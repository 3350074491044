/**
 * external libs
 */
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
/**
 * context
 */
import { GlobalContext } from './../App'
/**
 * utils
 */
import { getLocalRefreshToken, getLocalAccessToken, clearStorage } from './jwt'
import { EXTERNAL_BACKEND_URL } from './../constants'
/**
 * types
 */
import { GlobalContextType } from './../types'

const generateUUID = () => {
    const crypto = window.crypto;
    const array = new Uint8Array(16);
    crypto.getRandomValues(array);

    array[6] = (array[6] & 0x0f) | 0x40;
    array[8] = (array[8] & 0x3f) | 0x80;

    const hex = Array.from(array, byte => ('0' + byte.toString(16)).slice(-2)).join('');
    return [
        hex.substr(0, 8),
        hex.substr(8, 4),
        hex.substr(12, 4),
        hex.substr(16, 4),
        hex.substr(20, 12)
    ].join('-');
}

let uniqueIdLocal = generateUUID();

const useSender = () => {
    const { changeUser, setLoaderCount } = useContext<GlobalContextType>(GlobalContext)
    const navigate = useNavigate()

    const logOut = async () => {
        if (changeUser) {
            changeUser(null)
        }
        clearStorage()
        await sender.get('/user/logout')
        navigate('/')
    }

    const logIn = (token: string, refreshToken: string, uid?: string, urole?: string, uname?: string ) => {
        clearStorage()
        sessionStorage.setItem('accessToken', token)
        sessionStorage.setItem('refreshToken', refreshToken)
        localStorage.setItem('accessToken', token)
        localStorage.setItem('refreshToken', refreshToken)

        if(uid && urole && uname) {
            localStorage.setItem('uid', uid)
            localStorage.setItem('urole', urole)
            localStorage.setItem('uname', uname)
        }
    }

    const sender = axios.create({
        baseURL: EXTERNAL_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json',
            'device': btoa(JSON.stringify({"id":uniqueIdLocal,"token":"","os":"web","osVersion":"10.2","clientVersion":"0.1.2"})),
            // "X-Requested-With": "XMLHttpRequest",
            // "X-CSRFToken": getCookie("csrftoken"),
        },
        withCredentials: false,
    })

    sender.interceptors.request.use(
        (config: { headers: any }) => {
            if(setLoaderCount) {
                setLoaderCount(pre => pre + 1)
            }

            const token = getLocalAccessToken()
            if (!!token && token !== 'undefined') {
                config = {
                    ...config,
                    headers: { ...config.headers, Authorization: 'Bearer ' + token },
                }
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    sender.interceptors.response.use(
        (res) => {
            if(setLoaderCount) {
                setLoaderCount(pre => pre - 1)
            }

            return res
        },
        async (err) => {
            if(setLoaderCount) {
                setLoaderCount(pre => pre - 1)
            }
            
            const originalConfig = err.config
            if (err.response) {
                if (err.response.status === 401) {
                    if (!originalConfig._retry && getLocalRefreshToken() && originalConfig.url !== '/token/refresh') {
                        originalConfig._retry = true
                        try {
                            const rs = await sender.get('/token/refresh', {
                                headers: {
                                    'Refresh-Token': getLocalRefreshToken(),
                                    'Authorization': 'Bearer ' + getLocalRefreshToken()
                                },
                            })
                            const { access } = rs.data

                            localStorage.setItem('accessToken', access)
                            sessionStorage.setItem('accessToken', access)
                            sender.defaults.headers.common['Authorization'] = `Bearer ${access}`

                            return sender(originalConfig)
                        } catch (_error: any) {
                            await logOut()

                            if (_error.response && _error.response.data) {
                                return Promise.reject(_error.response.data)
                            }

                            return Promise.reject(_error)
                        }
                    } else {
                        await logOut()
                    }
                }
                if (err.response.status === 403) {
                    return Promise.reject(err)
                }
            }
            return Promise.reject(err)
        }
    )

    return { http: sender, logOut, logIn }
}

export default useSender
