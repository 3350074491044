/**
 * external libs
*/
import React, { lazy, Suspense } from 'react';
/**
 * components
*/
const Drivers = lazy(() => import("./Drivers"));

const DriversComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    return (
        <Suspense fallback={null}>
            <Drivers {...props} />
        </Suspense>
    );
};

export default DriversComponent;