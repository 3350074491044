/**
 * external libs
*/
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
/**
 * components
*/
import Button from "../../common-components/button/Button";
/**
 * context
*/
import { GlobalContext } from "./../../App";
/**
 * types
*/
import { GlobalContextType, RolesType } from "./../../types";
/**
 * styles
*/
import styles from './error-pages.module.scss';


const Patients: React.FC = () => {
    const { user } = useContext<GlobalContextType>(GlobalContext);
    const navigate = useNavigate();

    const backHandler = () => {
        if(user?.Role === RolesType.operator || user?.Role === RolesType.managers) {
            navigate(`/manage`, { replace: true })
        } else if(user?.Role){
            navigate(`/office`, { replace: true })
        } else {
            navigate(`/`, { replace: true })
        }
    }

    return (
        <div className={styles.page404}>
            <div className={styles.page404__header}>
                <p className={styles.page404__title}>{"404 :("}</p>
                <p className={styles.page404__error}>Ошибка</p>
            </div>

            <div className={styles.page404__footer}>
                <div className={styles.page404__texts}>
                    <p className={styles.page404__description}>К сожалению, запрашиваемая вами страница, не найдена</p>
                    <p className={styles.page404__propose}>Попробуйте вернуться на главную</p>
                </div>

                <Button
                    label="Вернуться на главную"
                    type="button"
                    subClasses={styles.page404__button}
                    handler={backHandler}
                />
            </div>
        </div>
    );
};

export default Patients;