/**
 * external libs
 */
import React, { lazy, Suspense, useContext } from 'react'
import { Navigate } from 'react-router-dom'
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * types
 */
import { GlobalContextType, RolesType } from './../../../types'
/**
 * components
 */
const GasStationOperators = lazy(() => import('./GasStationOperators'))

const GasStationOperatorsComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const { user } = useContext<GlobalContextType>(GlobalContext)

    if (user?.Role !== RolesType.managers) {
        return <Navigate to={'/404'} replace />
    }

    return (
        <Suspense fallback={null}>
            <GasStationOperators {...props} />
        </Suspense>
    )
}

export default GasStationOperatorsComponent
