/**
 * external libs
 */
import React, { PropsWithChildren, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Menu from './Menu'
import avatar from './../../assets/icons/avatar.svg'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import useSender from './../../utils/sender'
import { manageRoutes } from '../../routers/paths'
/**
 * types
 */
import { GlobalContextType, RolesTranslateType, RouteType, RolesType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

const ManagerLayout: React.FC<PropsWithChildren> = () => {
    const { user, changeUser } = useContext<GlobalContextType>(GlobalContext)
    const location = useLocation()
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [access, setAccess] = useState(false)
    const { logIn, logOut } = useSender()

    const routeData = useMemo<RouteType | undefined>(
        () => manageRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const firstVisitHandler = useCallback(() => {
        if (user?.uID && user?.Role && (user.Role === RolesType.operator || user.Role === RolesType.managers)) {
            setAccess(true)
        } else {
            const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
            const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
            const uIDLocal = localStorage.getItem('uid')
            const roleLocal = localStorage.getItem('urole')
            const unameLocal = localStorage.getItem('uname')

            if (accessToken && refreshToken && uIDLocal && roleLocal && changeUser) {
                logIn(accessToken, refreshToken, uIDLocal, roleLocal, unameLocal || '')
                changeUser({
                    Role: roleLocal as keyof typeof RolesType,
                    uID: uIDLocal,
                    uName: unameLocal,
                })

                setAccess(true)
            } else {
                logOut()
            }
        }
        setAccess(true)
    }, [user, setAccess])

    useEffect(() => {
        firstVisitHandler()
    }, [firstVisitHandler])

    if (!access) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? ''}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <div className={styles.officeLayout}>
                <div className={styles.officeLayout__drawerWrapper}>
                    <div
                        className={`${styles.officeLayout__drawer} ${drawerOpen ? styles.officeLayout__drawer_open : ''}`}>
                        <div className={styles.officeLayout__topLogo}>
                            <Link to="/manage" className={styles.officeLayout__topLogoLink}>
                                <svg className={styles.officeLayout__topLogoIco} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.2001 16.3111V10.0889H13.1112C8.6929 10.0889 5.11118 13.6706 5.11118 18.0889V21.3778C5.11118 25.796 8.6929 29.3778 13.1112 29.3778H27.2001V23.1555H13.3334C12.2288 23.1555 11.3334 22.2601 11.3334 21.1555V18.3111C11.3334 17.2065 12.2288 16.3111 13.3334 16.3111H27.2001Z" fill="#0057A6"/>
                                    <path d="M4.8 8.84441V2.62219H26.8889V8.84441H19.1111V21.9111H12.8889V8.84441H4.8Z" fill="#F7D417"/>
                                </svg>
                            </Link>

                            <div className={styles.officeLayout__opener}>
                                <button
                                    type="button"
                                    className={styles.officeLayout__openButton}
                                    onClick={() => setDrawerOpen((pre) => !pre)}>
                                    <svg
                                        className={styles.officeLayout__openIco}
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.6001 16.8L14.4001 12L9.6001 7.20005"
                                            strokeWidth="1.8"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        
                        <div className={styles.officeLayout__drawerScroll}>
                            <Menu />
                        </div>

                        <Link
                            to="/manage/profile"
                            className={styles.user}>
                            <div className={styles.user__info}>
                                <p className={styles.user__label}>{user?.uName || ''}</p>
                                {!!user?.Role && (
                                    <p className={styles.user__name}>{RolesTranslateType[user?.Role]}</p>
                                )}
                            </div>

                            <div className={styles.user__logoWrap}>
                                <img src={avatar} alt="avatar" />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className={styles.officeLayout__content}>
                    <div className={styles.officeLayout__contentBG}>
                        <div className="container">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManagerLayout
